import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as style from "./Supporters.module.css"

const SmallBusinessCivic = () => {
  const data = useStaticQuery(graphql`
    {
      idahoDefenseAlliance: file(
        relativePath: { eq: "idaho-defense-alliance.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      idahoVeterans: file(relativePath: { eq: "idvs-logo-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sayler: file(relativePath: { eq: "Sayler.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hagedorn: file(relativePath: { eq: "Hagedorn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      infinitySigns: file(relativePath: { eq: "infinity-signs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dar: file(relativePath: { eq: "DAR-Horz-Full-Color-Blue-text.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="tab-content">
      <Row className={`${style.starGarnet} my-4`}>
        <Row style={{width: '100%'}} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{color: 'white', textAlign: 'center !improtant'}}>Star Garnet - $2,500</h1>
          </Col>
        </Row>
        {/* <Row style={{width: '100%'}} >
        <Col>
          <Row>
            <Col sm={2}>
              <a href="https://bestboisesigns.com/" target="_blank" rel="noopener noreferrer">
              <Img
                fluid={data.infinitySigns.childImageSharp.fluid}
                alt="Infinity Signs Logo"
                style={{ maxWidth: "162px" }}
              />
              </a>
            </Col>
        
            <Col >
              <h2>Infinity Signs Northwest Boise, Idaho</h2>
              <h4>
              Build Your Brand Better!
              </h4>
            </Col>
          </Row> 
        </Col> 
        <Col>
          <Row>
            <Col sm={2}>
              <a href="https://www.dar.org/national-society/become-member/chapters-by-state/ID" target="_blank" rel="noopener noreferrer">
              <Img
                fluid={data.dar.childImageSharp.fluid}
                alt="Daughter of the American Revolution Logo"
                style={{ maxWidth: "162px" }}
              />
              </a>
            </Col>
        
            <Col >
              <h2>Daughters of the American Revolution</h2>
              <h4>
                Idaho State Society
              </h4>
            </Col>
          </Row>  
        </Col> 
        </Row>*/}
      </Row>

      <Row className={`${style.opal} my-4`}>
        <Row style={{width: '100%'}} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{textAlign: 'center !improtant'}}>Opal - $1,000</h1>
          </Col>
        </Row>
        <Col md={4}>
          <Row>
          <Col>
              <h3>Connie's</h3>
              <h4>
                Island Park, Idaho
              </h4>
        </Col>
        <Col>
              <a href="https://www.primuspolicy.com/">
                <p>
                  <h3>Primus Policy Group </h3>
                  <h4>
                  Government Affairs
                  </h4>
                </p>
              </a>
        </Col>
            </Row>
            </Col>
      </Row>

      <Row className={`${style.jasper} my-4`}>
        <Row style={{width: '100%'}} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{textAlign: 'center !improtant'}}>Jasper - $500</h1>
          </Col>
        </Row>
        <Row>
        <Col>
              <h3>Merrick RV Sales &amp; Service</h3>
              <h4>
                Blackfood, Idaho
              </h4>
        </Col>
        <Col>
          <h3>Hayden Lake Fraternal Order of Eagles</h3>
            <h4>Hayden, Idaho</h4>
        </Col>
        <Col>
        <h3>Coeur d'Alene FOE</h3>
          <h4>Coeur d'Alene, Idaho</h4>
        </Col>
        
        </Row> 
      </Row>
      <Row className={`${style.jade } my-4`}>
        <Row style={{width: '100%'}} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{textAlign: 'center !improtant'}}>Jade - $250</h1>
          </Col>
        </Row>
        <Row>
        <Col>
        <h3>Potandon Produce</h3>
        <h4>Idaho Falls, Idaho</h4>
        </Col>
        </Row>
      </Row>

      <Row className={`${style.topaz} my-4`}>
        <Row style={{width: '100%'}} className="mb-4">
          <Col className="text-center" md={12}>
            <h1 style={{textAlign: 'center !important'}}>Topaz - $100</h1>
          </Col>
        </Row>
        <Row>
        <Col style={{textAlign: 'center !important'}}>
        <h3>Daughters of the American Revolution<br /><span style={{fontSize: 'smaller'}}>LT George Farragut Chapter</span></h3>
        <h4>Coeur d'Alene, Idaho</h4>
        </Col>
        <Col style={{textAlign: 'center !important'}}>
        <h3>Boise Hawks Baseball</h3>
        <h4>Boise, Idaho</h4>
        </Col>
        <Col style={{textAlign: 'center !important'}}>
        <h3>American Legion Post 60</h3>
        <h4>Cascade, Idaho</h4>
        </Col>
        <Col style={{textAlign: 'center !important'}}>
        <h3>Ashton Rotary Club</h3>
          <h4>Ashton, Idaho</h4>
        </Col>
        </Row>
      </Row>

     
    </div>
  )
}

export default SmallBusinessCivic
