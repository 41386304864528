import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as style from "../Supporters.module.css"
import MessageFromModalButton from "../MessageFromModal/MessageFromModalButton"
import IdahoPowerLogo from '../IdahoPowerLogo'


const GoldLevel = () => {
    const data = useStaticQuery(graphql`
    {
      dennisJohnson: file(relativePath: { eq: "dennis_johnson.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lisaGrow: file(relativePath: { eq: "lisa_grow.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 130, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      unitedHeritage: file(relativePath: { eq: "uh_web_logo_80-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      albertsons: file(relativePath: { eq: "albertsons.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
      <div className="tab-content">
        
        <hr />
        <h1 className="text-center">Gold Level Corporate Donors - $25,000+</h1>
        
    {/* <Row className={`${style.goldLevel} my-4`}>
      <Col md={2} className="text-center">
        <IdahoPowerLogo />
      </Col>
      <Col md={10}>
        <h2>Idaho Power</h2>
        <h4>Idaho Power, headquartered in vibrant and fast-growing Boise, Idaho, has been a locally operated energy company since 1916. The company’s goal to provide 100% clean energy by 2045 builds on our long history as a clean-energy leader that provides reliable service at affordable prices.</h4>
        <MessageFromModalButton 
        messagePerson="Lisa Grow"
        personTitle="CEO of Idaho Power"
        partnerUrl="https://www.idahopower.com"
        partner="Idaho Power"
        message="Message from the CEO of Idaho Power, Lisa Grow"
        personPhoto={data.lisaGrow.childImageSharp.fluid}
      />
      </Col>
    </Row>
    <Row className={`${style.goldLevel} my-4`}>
    <Col md={2} className="text-center">
      <Img fluid={data.unitedHeritage.childImageSharp.fluid} alt="United Heritage Insurance" style={{maxWidth: '100px'}} />
    </Col>
    <Col md={10}>
      <h2>United Heritage Insurance</h2>
      <h4>United Heritage Insurance is a family of companies that combines life and property & casualty insurers under our Mutual Holding Company. Our companies were founded over a century ago and have served our communities and customers with financial strength and service excellence through many key events in our country’s history. Our 200 employees and thousands of independent agents across the country strive to meet our vision every day.</h4>
      <MessageFromModalButton 
        messagePerson="Dennis Johnson"
        personTitle="CEO of United Heritage Insurance"
        partnerUrl="https://www.unitedheritage.com/index.php"
        partner="United Heritage Insurance"
        message="A message from CEO of United Heritage Insurance"
        personPhoto={data.dennisJohnson.childImageSharp.fluid}
      />
    </Col>
  </Row>
  <Row className={`${style.goldLevel} my-4`}>
    <Col md={2} className="text-center">
     <Img fluid={data.albertsons.childImageSharp.fluid} alt="Albertsons logo" />
    </Col>
    <Col md={10}>
      <h2>Albertsons</h2>
      <h4>Today, Albertsons operates as a banner of Albertsons Companies, one of the largest food and drug retailers in the United States. With both a strong local presence and national scale, the company operates stores across 35 states and the District of Columbia under 20 well-known banners.</h4>
      <MessageFromModalButton 
        messagePerson="John Colgrove"
        personTitle="VP Marketing &amp; Merchandising"
        partnerUrl="https://www.albertsons.com"
        partner="Albertsons"
        message="Message from John Colgrove, VP Marketing and Merchandising. Intermountain Division at Albertsons LLC"
      />
    </Col>
  </Row> */}
  </div>
  )
}

export default GoldLevel
